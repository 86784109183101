import React, { useEffect } from "react";
import "./Service.css";
import { Link } from "react-router-dom";


const Service = () => {
  const redirect = ()=>{
    console.log("first")
    window.location.href="/tiles"
}
  const MyProduct = [
    {
      id:1,
      img:"https://img.freepik.com/free-photo/top-view-paint-can_23-2149705336.jpg?size=626&ext=jpg&uid=R134274668&ga=GA1.1.229895539.1703719784&semt=sph",
      title:"Paints",
    },
    {
      id:2,
      img:"https://img.freepik.com/free-photo/modern-luxury-hotel-office-reception-lounge-with-meeting-room_105762-1772.jpg?size=626&ext=jpg&uid=R134274668&ga=GA1.1.229895539.1703719784&semt=sph",
      title:"Tiles",
    },
    {
      id:3,
      img:"https://img.freepik.com/free-photo/different-electrical-tools-wooden-background-flat-lay_169016-24825.jpg?w=740&t=st=1707550904~exp=1707551504~hmac=24fe2d91e8a54f44b1fd62d64e99592a019204ba8661e86d3475620e609b0dce",
      title:"Electricals",
    },
    {
      id:4,
      img:"https://img.freepik.com/free-photo/high-angle-measuring-tools-still-life_23-2150440970.jpg?size=626&ext=jpg&uid=R134274668&ga=GA1.1.1097436010.1705554726&semt=sph",
      title:"building repair services",
    },
    {
      id:5,
      img:"https://img.freepik.com/free-vector/wooden-textures-pack_1053-293.jpg?size=626&ext=jpg&uid=R134274668&ga=GA1.1.229895539.1703719784&semt=ais",
      title:"Ply Wood",
    },
    {
      id:6,
      img:"https://img.freepik.com/free-photo/man-fixing-kitchen-sink_53876-13430.jpg?size=626&ext=jpg&uid=R134274668&ga=GA1.1.229895539.1703719784&semt=sph",
      title:"Plumbing",
    },
    {
      id:7,
      img:"https://img.freepik.com/free-photo/photovoltaic-solar-power-panel-field-green-clean-alternative-power-energy-concept-ai-generative_123827-23424.jpg?size=626&ext=jpg&uid=R134274668&ga=GA1.1.229895539.1703719784&semt=ais",
      title:"All solar products",
    },
    {
      id:8,
      img:"https://img.freepik.com/free-photo/beautiful-green-kitchen-interior-design_23-2150230375.jpg?size=626&ext=jpg&uid=R134274668&ga=GA1.1.229895539.1703719784&semt=ais",
      title:"Modular Kitchen",
    }
  ]


  return (
    <div className="product-container">
      <h2>Our Products</h2>
      <div className="products-page" >
        {MyProduct.map((item,index)=>(
            <div key={index} onClick={index === 1 ? redirect : null} className="productItem" >
            <div className="product-img">
              <img
                src={item.img}
                alt="img"
              />
            </div>
            <div className="overlay">
              <h2>{item.title}</h2>
             <button   className="btn" >Click Me</button>
            </div>
          </div>

        ))}
      </div>
    </div>
  );
};

export default Service;
