import React from 'react'
import './Tiles.css'
import t1 from '../Assest/t1.PNG'
import t2 from '../Assest/t2.PNG'
import t3 from '../Assest/t3.PNG'
import t4 from '../Assest/t4.PNG'
import t5 from '../Assest/t5.PNG'
import t6 from '../Assest/t6.PNG'
import t7 from '../Assest/t7.PNG'
import t8 from '../Assest/t8.PNG'
import { Link } from 'react-router-dom'

const Tiles = () => {
    const redirect = ()=>{
        console.log("first")
        window.location.href="/contact"
    }
  return (
    <div className='tiles-container'>
        <h2>Our Tiles</h2>
        <div className='tiles'>
        <div className="tilesItem" onClick={redirect}>
            <div className="tiles-img">
              <img
                src={t1}
                alt="img"
              />
            </div>
            <div className="overlay">
              {/* <h2>tiles</h2> */}
             <button  className="btn"><Link to="/contact">Click Me</Link></button>
            </div>
          </div>
        <div className="tilesItem" onClick={redirect}>
            <div className="tiles-img">
              <img
                src={t2}
                alt="img"
              />
            </div>
            <div className="overlay">
              {/* <h2>tiles</h2> */}
             <button  className="btn"><Link to="/contact">Click Me</Link></button>
            </div>
          </div>
        <div className="tilesItem" onClick={redirect}>
            <div className="tiles-img">
              <img
                src={t3}
                alt="img"
              />
            </div>
            <div className="overlay">
              {/* <h2>tiles</h2> */}
             <button  className="btn"><Link to="/contact">Click Me</Link></button>
            </div>
          </div>
        <div className="tilesItem" onClick={redirect}>
            <div className="tiles-img">
              <img
                src={t4}
                alt="img"
              />
            </div>
            <div className="overlay">
              {/* <h2>tiles</h2> */}
             <button  className="btn"><Link to="/contact">Click Me</Link></button>
            </div>
          </div>
        <div className="tilesItem" onClick={redirect}>
            <div className="tiles-img">
              <img
                src={t5}
                alt="img"
              />
            </div>
            <div className="overlay">
              {/* <h2>tiles</h2> */}
             <button  className="btn"><Link to="/contact">Click Me</Link></button>
            </div>
          </div>
        <div className="tilesItem" onClick={redirect}>
            <div className="tiles-img">
              <img
                src={t6}
                alt="img"
              />
            </div>
            <div className="overlay">
              {/* <h2>tiles</h2> */}
             <button  className="btn"><Link to="/contact">Click Me</Link></button>
            </div>
          </div>
        <div className="tilesItem" onClick={redirect}>
            <div className="tiles-img">
              <img
                src={t7}
                alt="img"
              />
            </div>
            <div className="overlay">
              {/* <h2>tiles</h2> */}
             <button  className="btn"><Link to="/contact">Click Me</Link></button>
            </div>
          </div>
        <div className="tilesItem" onClick={redirect}>
            <div className="tiles-img">
              <img
                src={t8}
                alt="img"
              />
            </div>
            <div className="overlay">
              {/* <h2>tiles</h2> */}
             <button  className="btn"><Link to="/contact">Click Me</Link></button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Tiles